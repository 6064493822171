import { useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../api";
import { useDispatch } from "react-redux";
import { clearCart } from "../../state";

const API_BASE_URL = BASE_URL;

const handlePrint = () => {
  const printContents = document.getElementById("printArea").innerHTML;

  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe);

  iframe.contentDocument.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          // Voeg hier eventueel aanvullende stijlen toe voor de printversie
        </style>
      </head>
      <body>${printContents}</body>
    </html>
  `);

  iframe.contentDocument.close();
  iframe.contentWindow.print();

  iframe.parentNode.removeChild(iframe);
};

const Confirmation = () => {

// Function to clean up cookies
const clearCookiesExcept = (exceptions) => {
  document.cookie.split(";").forEach((c) => {
    const key = c.split("=")[0].trim();
    if (!exceptions.includes(key)) {
      document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  });
};

// Function to clean up storage (both local and session)
const clearStorageExcept = (exceptions, storageType) => {
  Object.keys(storageType).forEach((key) => {
    if (!exceptions.includes(key)) {
      storageType.removeItem(key);
    }
  });
};

// Clear cookies and storage, except 'favorites', 'cookiesAccepted' and 'jwt', after the component is fully loaded
useEffect(() => {
  window.addEventListener('load', () => {
    clearCookiesExcept(['favorites', 'jwt', 'cookiesAccepted']);
    clearStorageExcept(['favorites', 'jwt', 'cookiesAccepted'], localStorage);
    clearStorageExcept(['favorites', 'jwt', 'cookiesAccepted'], sessionStorage);
  });
}, []);


  const [order, setOrder] = useState(null);
  const { sessionId: urlSessionId } = useParams();
  const storedSessionId = localStorage.getItem("recentSessionId");
  const sessionId = urlSessionId || storedSessionId;

  const dispatch = useDispatch();

  const getFreeProducts = async (totalAmount) => {
    const response = await fetch(`${API_BASE_URL}/api/free-items?populate=*`);
    const data = await response.json();

    // Separate products with freeFromPrice of 0
    const alwaysFreeProducts = data.data.filter(
      (p) => p.attributes.freeFromPrice === 0
    );

    // Filter other products that qualify as free based on the totalAmount
    const qualifiedFreeProducts = data.data
      .filter(
        (p) =>
          p.attributes.freeFromPrice > 0 &&
          p.attributes.freeFromPrice <= totalAmount
      )
      .sort((a, b) => b.attributes.freeFromPrice - a.attributes.freeFromPrice); // Sort in descending order

    // Add the most expensive qualified product (if any exist)
    const mostExpensiveFreeProduct =
      qualifiedFreeProducts.length > 0 ? [qualifiedFreeProducts[0]] : [];

    // Combine the always free products and the most expensive qualified product
    const combinedFreeProducts = [
      ...alwaysFreeProducts,
      ...mostExpensiveFreeProduct,
    ];

    return combinedFreeProducts;
  };

  useEffect(() => {
    dispatch(clearCart());

    const fetchOrder = async () => {
      if (!sessionId) {
        console.error("No session id");
        return;
      }
      try {
        const response = await fetch(
          `${BASE_URL}/api/orders?filters[stripeSessionId]=${sessionId}`
        );
        const orderData = await response.json();

        if (orderData.data?.length > 0) {
          const fullOrder = orderData.data[0];
          const totalAmount = fullOrder.attributes.products.reduce(
            (acc, product) => acc + product.price * product.count,
            0
          );
          const freeProducts = await getFreeProducts(totalAmount);
          const updatedOrder = {
            ...fullOrder.attributes,
            id: fullOrder.id,
            products: [
              ...fullOrder.attributes.products,
              ...freeProducts.map((product) => {
                const actualProduct = product.attributes
                  ? product.attributes
                  : product;
                return {
                  ...actualProduct,
                  price: 0,
                  count: 1,
                };
              }),
            ],
          };
          setOrder(updatedOrder);
          updatedOrder.products.forEach((product, index) => {
            console.log(`Product ${index + 1}:`, product);
          });
        }
      } catch (error) {
        console.error("Error fetching order", error);
      }
    };

    fetchOrder();

    return () => {
      localStorage.removeItem("recentSessionId");
    };
  }, [sessionId]);

  if (!order) {
    return (
      <Box className="shift-on-search" m="80px auto" height="50vh">
        <Alert severity="error">
          <AlertTitle>Error!</AlertTitle>
          Er is een probleem opgetreden bij het ophalen van de orderdetails.
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      className="shift-on-search"
      m="80px auto"
      width="80%"
      height="100%"
      p={3}
      bgcolor="white"
    >
      <Alert severity="success">
        <AlertTitle>Gelukt!</AlertTitle>
        Je bestelling is succesvol ontvangen, dankjewel! —
        <strong>Je kunt hieronder je afhaalbewijs afdrukken.</strong>
      </Alert>

      <Box
        id="printArea"
        m="20px auto"
        width="210mm"
        height="297mm"
        border="1px solid black"
        p={3}
        bgcolor="white"
      >
        <Box display="flex" justifyContent="flex-start">
          <span className="MuiTypography-root MuiTypography-h3title vuurwerk-bruchem-text css-w65vrb-MuiTypography-root">
            <b>VUURWERK BRUCHEM</b>
          </span>
        </Box>

        <Typography align="left" gutterBottom>
          Dorpsstraat 20
        </Typography>
        <Typography align="left" gutterBottom>
          {" "}
          5314 AE Bruchem
        </Typography>
        <Typography align="left" gutterBottom>
          0418 700 511
        </Typography>
        <Typography align="left" gutterBottom>
          VUURWERKBRUCHEM.NL
        </Typography>

        <hr style={{ margin: "20px 0" }} />

        <Box my={3}>
          <Typography>
            <strong>Bestelnummer:</strong> {order.id}
          </Typography>
          <Typography>
            <strong>Naam:</strong> {order.userName}
          </Typography>
          <Typography>
            <strong>Email:</strong> {order.email}
          </Typography>
          <Typography>
            <strong>Telefoon:</strong> {order.phoneNumber}
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Afhaalgegevens
        </Typography>
        <Typography>
          <strong>Datum:</strong> {order.pickupDate} december 2023
        </Typography>
        <Typography>
          <strong>Tijd:</strong> {order.pickupTimeSlot}
        </Typography>
        <Typography>
          <strong>Status:</strong> {order.stripePaymentStatus}
        </Typography>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            Producten
          </Typography>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                  Art. Nummer
                </th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                  Naam
                </th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                  Prijs
                </th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                  Aantal
                </th>
                <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                  Subtotaal
                </th>
              </tr>
            </thead>

            <tbody>
              {order.products.map((product) => (
                <tr key={product.id}>
                  <td style={{ paddingBottom: "8px" }}>{product.artNumber}</td>
                  <td style={{ paddingBottom: "8px" }}>{product.name}</td>
                  <td style={{ paddingBottom: "8px" }}>
                    €{product.price ? product.price.toFixed(2) : "0.00"}
                  </td>
                  <td style={{ paddingBottom: "8px" }}>{product.count}</td>
                  <td style={{ paddingBottom: "8px" }}>
                    €{(product.price * product.count).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" style={{ paddingTop: "10px" }}>
                  <strong>Totaal:</strong>
                </td>
                <td style={{ paddingTop: "10px" }}>
                  <strong>
                    €
                    {order.products
                      .reduce(
                        (acc, product) => acc + product.price * product.count,
                        0
                      )
                      .toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tfoot>
          </table>
        </Box>

        <Typography align="center" gutterBottom>
          Dank voor uw bestelling bij Vuurwerk Bruchem!
        </Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handlePrint}>
        Print Afhaalbewijs
      </Button>
    </Box>
  );
};

export default Confirmation;
