
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="shift-on-search" style={{ width: "80%", margin: "auto", marginTop: "80px" }}>
      <h1>Privacyverklaring</h1>
      
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Inleiding</h2>
        <p>Wij respecteren de privacy van onze klanten en bezoekers van onze website en zorgen ervoor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld.</p>

        <h2>Welke gegevens verzamelen we?</h2>
        <p>We verzamelen enkel informatie die noodzakelijk is voor de werking van onze website en de verkoop van vuurwerk. Dit omvat:</p>
        <ul>
          <li>Contactgegevens zoals naam, e-mailadres en telefoonnummer</li>
          <li>Bestelgegevens</li>
          <li>Betalingsinformatie</li>
        </ul>

        <h2>Hoe gebruiken we deze gegevens?</h2>
        <p>De verzamelde gegevens worden uitsluitend gebruikt voor relevante activiteiten gerelateerd aan de verkoop van vuurwerk.</p>

        <h2>Bewaartermijn</h2>
        <p>We bewaren uw gegevens volgens de richtlijnen van de wet en zorgen voor een adequate beveiliging.</p>

        <h2>Contact</h2>
        <p>Als u vragen of opmerkingen heeft over onze privacyverklaring, neem dan contact met ons op.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
