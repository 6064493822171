import { useSelector } from "react-redux";
import { Box, Button, Stepper, Step, StepLabel } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { shades } from "../../theme";
import Payment from "./Payment";
import Shipping from "./Shipping";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../AuthContext";
import { BASE_URL } from "../../api";
const stripePromise = loadStripe(
  "pk_live_51NlTPCBpstOm0aiz9usXKz2U3TSSJ3GKTJE285bm6UCFEO9ri2TuHqScGqXUVnHRse3voKKgp6IwIuGsxkocFkPl00qBMTd4sA"
);

const Checkout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const cart = useSelector((state) => state.cart.cart);
  const { userData } = useAuth();
  const isFirstStep = activeStep === 0;
  const isSecondStep = activeStep === 1;
  const initialValues = {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    country: "Nederland", // Altijd Nederland?
    street: userData?.streetName || "",
    houseNumber: userData?.houseNumber || "",
    city: userData?.city || "",
    postalCode: userData?.postalCode || "",
    email: userData?.email || "",
    phoneNumber: userData?.phoneNumber || "",
    pickupDate: "",
    pickupTimeSlot: "",
  };

  const handleFormSubmit = async (values, actions) => {
    setActiveStep(activeStep + 1);

    if (isSecondStep) {
      makePayment(values);
    }

    actions.setTouched({});
  };

  async function makePayment(values) {
    const stripe = await stripePromise;
    const requestBody = {
      userName: [values.firstName, values.lastName].join(" "),
      email: values.email,
      phoneNumber: values.phoneNumber,
      pickupDate: values.pickupDate,
      pickupTimeSlot: values.pickupTimeSlot,
      products: cart.map(({ id, count }) => ({
        id,
        count,
      })),
    };

    const response = await fetch(`${BASE_URL}/api/orders`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });

    const session = await response.json();
    localStorage.setItem("recentSessionId", session.id);

    await stripe.redirectToCheckout({
      sessionId: session.id,
    });
  }

  return (
    <Box width="80%" m="100px auto">
      <Stepper activeStep={activeStep} sx={{ m: "20px 0" }}>
        <Step>
          <StepLabel>Gegevens</StepLabel>
        </Step>
        <Step>
          <StepLabel>Betaling</StepLabel>
        </Step>
      </Stepper>
      <Box>
    <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema[activeStep]}
    >
        {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
        }) => (
            <form onSubmit={handleSubmit}>
                {isFirstStep && (
                    <Shipping
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                )}
                {isSecondStep && (
                    <Payment
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                )}

                {(activeStep !== 0 && activeStep !== 1) ? (
                    <div className="loading-indicator">
                        <p>Ogenblik geduld, we verwijzen je door naar de betalingspagina...</p>
                    </div>
                ) : (
                    <Box display="flex" justifyContent="space-between" gap="50px">
                        {!isFirstStep && (
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                sx={{
                                    backgroundColor: shades.primary[200],
                                    boxShadow: "none",
                                    color: "white",
                                    borderRadius: 0,
                                    padding: "15px 40px",
                                }}
                                onClick={() => setActiveStep(activeStep - 1)}
                            >
                                Vorige
                            </Button>
                        )}
                        <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            sx={{
                                backgroundColor: shades.primary[400],
                                boxShadow: "none",
                                color: "white",
                                borderRadius: 0,
                                padding: "15px 40px",
                            }}
                        >
                            {!isSecondStep ? "Volgende" : "Bestelling plaatsen"}
                        </Button>
                    </Box>
                )}
            </form>
        )}
    </Formik>
</Box>

    </Box>
  );
};



const shippingSchema = yup.object().shape({
  firstName: yup.string().required("Vul je voornaam in"),
  lastName: yup.string().required("Vul je achternaam in"),
  country: yup.string().required("Dit is een verplicht veld"),
  street: yup.string().required("Vul je straatnaam in"),
  houseNumber: yup.string().required("Vul je huisnummer in"),
  city: yup.string().required("Vul je woonplaats in"),
  postalCode: yup.string().required("Voer je postcode in"),
});

const paymentSchema = yup.object().shape({
  email: yup
    .string()
    .required(
      "We hebben je e-mailadres nodig om de bestelbevestiging op te sturen"
    ),
  phoneNumber: yup
    .string()
    .required(
      "We hebben je telefoonnummer nodig om je te bereiken als er wat is met je bestelling"
    ),
  pickupTimeSlot: yup
    .string()
    .required(
      "Om te zorgen dat je bestelling op tijd klaar staat, moeten we je ophaalmoment weten"
    ),
  pickupDate: yup
    .string()
    .required(
      "Om te zorgen dat je bestelling op tijd klaar staat, moeten we je ophaalmoment weten"
    ),
});

const checkoutSchema = [shippingSchema, paymentSchema];

export default Checkout;
