import { Box, Typography, TextField } from "@mui/material";
import Select from "react-select";
import { useFormikContext } from "formik";

const CustomReactSelect = ({ name, options }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext();

  return (
    <div>
      <Select
        name={name}
        options={options}
        value={
          options ? options.find((option) => option.value === values[name]) : ""
        }
        onChange={(option) => setFieldValue(name, option.value)}
      />
      {errors[name] && touched[name] && (
        <div style={{ color: "red", marginTop: ".5rem" }}>{errors[name]}</div>
      )}
    </div>
  );
};

const Payment = ({ values, touched, errors, handleBlur, handleChange }) => {
  return (
    <Box m="30px 0">
      {/* CONTACT INFO */}
      <Box>
        <Typography sx={{ mb: "15px" }} fontSize="18px">
          Contact Info
        </Typography>
        <TextField
          fullWidth
          type="text"
          label="Email"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          name="email"
          error={!!touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          sx={{ gridColumn: "span 4", marginBottom: "15px" }}
        />
        <TextField
          fullWidth
          type="text"
          label="Phone Number"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.phoneNumber}
          name="phoneNumber"
          error={!!touched.phoneNumber && !!errors.phoneNumber}
          helperText={touched.phoneNumber && errors.phoneNumber}
          sx={{ gridColumn: "span 4" }}
        />
      </Box>
      <Box mt="20px">
        <Typography sx={{ mb: "5px" }} fontSize="18px">
          Kies je afhaalmoment
        </Typography>
        <Typography sx={{ mb: "15px" }} fontSize="12px" color="textSecondary">
          of laat ons voor je kiezen op basis van de verwachte drukte. (laat in
          dat geval de onderstaande keuze onaangepast)
        </Typography>

        <CustomReactSelect
          name="pickupDate"
          options={[
            { value: "28", label: "28 december" },
          ]}
        />

        <CustomReactSelect
          name="pickupTimeSlot"
          options={[
            { value: "07:00", label: "07:30" },
            { value: "07:30", label: "08:00" },
            { value: "08:30", label: "09:00" },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Payment;
