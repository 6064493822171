import { BASE_URL } from "../../api";
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Item from "../../components/Item";
import { useLocation } from "react-router-dom";

const API_BASE_URL = `${BASE_URL}`;

const SearchResults = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("query");

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchSearchResults();
  }, [query]);

  const fetchSearchResults = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/items?_q=${query}&populate=image`
      );
      if (response.ok) {
        const data = await response.json();
        setSearchResults(data.data);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <Box width="80%" m="80px auto" className="shift-on-search">
      <Typography variant="h3" mb={4}>
        Zoekresultaten
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        gap="20px"
        justifyContent="space-between"
      >
        {Array.isArray(searchResults) &&
          searchResults.map((item) => (
            <Box key={item.id} width={{ xs: "100%", sm: "48%", md: "31%" }}>
              <Item item={item} />
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default SearchResults;
