import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Link,
} from "@mui/material";
import { useAuth } from "../AuthContext";
import { fetchOrdersForUser } from "../api";

const OrdersPage = () => {
  const { userData } = useAuth();
  const [userOrders, setUserOrders] = useState([]);
  const [showAllOrders, setShowAllOrders] = useState(false);

  useEffect(() => {
    if (userData) {
      fetchOrdersForUser(userData.email)
        .then((orders) => {
          const sortedOrders = orders.data.sort((a, b) => 
            new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
          );
          setUserOrders(sortedOrders);
        })
        .catch((error) => console.error("Error fetching orders:", error));
    }
  }, [userData]);
  

  return (
    <Container component="main" maxWidth="md" style={{ marginTop: "64px" }}>
      <div className="paper">
        <Typography variant="h5" component="div">
          Mijn Bestellingen
        </Typography>
        <Box mt={2}>
          {userOrders && userOrders.length > 0 ? (
            (showAllOrders ? userOrders : userOrders.slice(0, 4)).map(
              (order) => (
                <Card key={order.id} style={{ marginBottom: "20px" }}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      Bestelling Nummer: {order.id}
                    </Typography>
                    <Typography color="textSecondary">
                      Datum van bestelling:{" "}
                      {new Date(order.attributes.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography color="textSecondary">
                      Totaalbedrag: €
                      {order.attributes.products.reduce(
                        (total, product) => total + product.price * product.count,
                        0
                      )}
                    </Typography>
                    <Typography color="textSecondary">
                      Afhaaldag: {order.attributes.pickupDate} december
                    </Typography>
                    <Box mt={2}>
                      <Link
                        href={order.attributes.successUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Meer details en afhaalbewijs
                      </Link>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Status:{" "}
                      {order.attributes.stripePaymentStatus === "paid"
                        ? "Betaald"
                        : "Niet betaald"}
                    </Typography>
                  </CardActions>
                </Card>
              )
            )
          ) : (
            <Typography color="textSecondary">
              Geen bestellingen gevonden.
            </Typography>
          )}
          {userOrders.length > 4 && !showAllOrders && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAllOrders(true)}
            >
              Meer
            </Button>
          )}
        </Box>
      </div>
    </Container>
  );
};

export default OrdersPage;
