
import React, { useState, useEffect } from "react";

const CookieWall = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const accepted = localStorage.getItem("cookiesAccepted");
    if (accepted !== "true") {
      setShow(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShow(false);
  };

  const rejectCookies = () => {
    window.location.href = "https://www.google.com";
  };

  if (!show) {
    return null;
  }

  return (
    <div style={{
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: "#f1f1f1",
      padding: "10px",
      textAlign: "center"
    }}>
      <p style={{ margin: 0 }}>
        Deze website maakt gebruik van functionele en analytische cookies om uw gebruikerservaring te verbeteren.
        We gebruiken deze informatie uitsluitend voor relevante activiteiten gerelateerd aan de verkoop van vuurwerk.
        Door op 'Ja' te klikken, stemt u in met het gebruik van deze cookies.
        Lees onze <a href="/privacy-policy">privacyverklaring</a> voor meer informatie.
      </p>
      <button style={{ margin: "5px" }} onClick={acceptCookies}>Ja</button>
      <button style={{ margin: "5px" }} onClick={rejectCookies}>Nee</button>
    </div>
  );
};

export default CookieWall;
