import { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Typography, useTheme, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { shades } from "../theme";
import { addToCart } from "../state";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../api";

const Item = ({ item, width }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [isHovered, setIsHovered] = useState(false);
  const {
    palette: { neutral },
  } = useTheme();

  const { category, price, priceDiscount, name, amount, image, outOfStock } =
    item.attributes;
  const url =
    image?.data?.attributes?.formats?.medium?.url ||
    image?.data?.attributes?.url;

  return (
    <Box width={width}>
      <Box
        position="relative"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <img
          style={{
            objectFit: "cover",
            maxWidth: "100%",
            height: "300px",
            cursor: "pointer",
          }}
          alt={name}
          width="300px"
          height="400px"
          src={`${BASE_URL}${url}`}
          onClick={() => navigate(`/item/${item.id}`)}
        />
        <Box
          display={isHovered ? "block" : "none"}
          position="absolute"
          bottom="10%"
          left="0"
          width="100%"
          padding="0 5%"
        >
          <Box display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              backgroundColor={shades.neutral[100]}
              borderRadius="3px"
            >
              <IconButton
                onClick={() => setCount(Math.max(count - 1, 1))}
                disabled={outOfStock}
              >
                <RemoveIcon />
              </IconButton>
              <Typography color={shades.primary[300]}>{count}</Typography>
              <IconButton
                onClick={() => setCount(count + 1)}
                disabled={outOfStock}
              >
                <AddIcon />
              </IconButton>
            </Box>
            <Button
              onClick={() => {
                dispatch(addToCart({ item: { ...item, count } }));
              }}
              sx={{
                backgroundColor: outOfStock
                  ? neutral.dark
                  : shades.primary[300],
                color: "white",
              }}
              disabled={outOfStock}
            >
              {outOfStock ? "Uitverkocht" : "In winkelwagen"}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box mt="3px">
        <Typography variant="subtitle2" color={neutral.dark}>
          {category
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())}
        </Typography>
        <Typography>{name} </Typography>
        {amount && <Typography>{amount} stuks</Typography>}

        {/* Hier voeg je de aangepaste prijsweergave toe */}
        {priceDiscount !== price ? (
          <>
            <Typography sx={{ textDecoration: "line-through" }}>
              €{parseFloat(price).toFixed(2)}
            </Typography>
            <Typography fontWeight="bold">
              €{parseFloat(priceDiscount).toFixed(2)}
            </Typography>
          </>
        ) : (
          <Typography>€{parseFloat(price).toFixed(2)}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Item;
