
import React from "react";

const VerkoopVoorwaarden = () => {
  return (
    <div className="shift-on-search" style={{ width: "80%", margin: "auto", marginTop: "80px" }}>
      <h1>Algemene Leveringsvoorwaarden & Betalingsvoorwaarden</h1>
        <p>Vuurwerk Bruchem is een onderdeel van Dingemans Machines.</p>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Artikel 1: Definities</h2>
        <p>
        Onder ‘consument’ wordt in deze voorwaarden verstaan de koper en/of
        opdrachtgever, natuurlijk persoon, die gebruik maakt van de goederen
        van deze webshop anders dan in de uitoefening van zijn beroep of
        bedrijf.
        </p>
        <p>
        Onder 'Vuurwerk Bruchem' wordt in deze voorwaarden verstaan een
        natuurlijk persoon of rechtspersoon die als relatie van Dingemans
        Machines zijn detailhandel in consumentenvuurwerk uitoefent.
        </p>
        <p>
        De navolgende voorwaarden zijn van toepassing op de overeenkomsten
        gesloten tussen de consument en Vuurwerk Bruchem.
        </p>

        <h2>Artikel 2: Vooruitbetaling</h2>
        <p>
        Vuurwerk Bruchem is gerechtigd alvorens werkzaamheden en leveringen
        aan te vangen, vooruitbetalingen van de consument te verlangen voor de
        nakoming van de betalingsverplichtingen van de consument tot maximaal
        100% van de overeengekomen prijs. De overeenkomst tussen Vuurwerk
        Bruchem en de consument is definitief nadat de consument bij het
        afhalen van de producten het kopie van het afhaalbewijs in bezit van
        Vuurwerk Bruchem tekent ter acceptatie van de producten.
        </p>
        <p>
        Indien Vuurwerk Bruchem surséance van betaling krijgt of failliet
        wordt verklaard en een vooruitbetaling niet of niet volledig kan
        worden terugbetaald, zal Dingemans Machines op verzoek van de
        consument één of meer dealers aanwijzen die de overeenkomst alsnog
        zullen uitvoeren onder verrekening van de vooruitbetaalde som. Deze
        aanwijzing zal zo spoedig mogelijk geschieden, maar in ieder geval
        binnen tien werkdagen nadat de consument daartoe zijn verzoek bij
        Dingemans Machines heeft ingediend en zoveel mogelijk in overleg met
        de consument.
        </p>

        <h2>Artikel 3: Recht van terughouding</h2>
        <p>
        Vuurwerk Bruchem is bevoegd tot toepassing van het hem wettelijk
        toekomende recht tot terughouding (retentie) indien de betaling niet
        (tijdig) is voldaan. Geen retentierecht komt Vuurwerk Bruchem toe
        indien hij eigenmachtig bepaalde diensten verricht c.q. werkzaamheden
        uitvoert, waarvoor de consument hem geen opdracht heeft verstrekt.
        </p>

        <h2>Artikel 4: Overmacht</h2>
        <p>
        Onder overmacht wordt verstaan elke tekortkoming die Vuurwerk Bruchem
        niet kan worden toegekend. Toerekening vindt niet plaats als de
        tekortkoming niet is te wijten aan zijn schuld, noch krachtens wet,
        rechtshandeling of in het verkeer geldende opvatting voor zijn
        rekening komt.
        </p>
        <p>
        Een gedane vooruitbetaling zal worden terugbetaald, maar de consument
        is wel gehouden de reeds door de ondernemer gemaakt kosten te
        vergoeden voor zover deze ten goede van de consument zijn gekomen.
        </p>

        <h2>Artikel 5: Ontbinding</h2>
        <p>
        Iedere tekortkoming van Vuurwerk Bruchem in de nakoming van één van
        zijn verplichtingen geeft aan de consument de bevoegdheid om de
        overeenkomst geheel of gedeeltelijk te ontbinden, tenzij de
        tekortkoming - gezien haar bijzondere aard of geringe betekenis - deze
        ontbinding met haar gevolgen niet rechtvaardigt.
        </p>

        <h2>Artikel 6: Afhalen</h2>
        <p>
        De afhaaltermijn geldt als vaste termijn, tenzij partijen anders
        overeenkomen.
        </p>
        <p>
        Bij het niet binnen de termijn afhalen van de bestelde goederen door
        de consument, heeft Vuurwerk Bruchem het recht de overeenkomst
        ongeldig te verklaren.
        </p>
        <p>
        Indien de overeenkomst door Vuurwerk Bruchem ongeldig wordt verklaard
        na een vooruitbetaling van de consument, ontvangt deze zijn
        vooruitbetaalde bedrag retour.
        </p>
        <p>
        De gekochte en betaalde goederen kunnen worden afgehaald bij Vuurwerk
        Bruchem gedurende de officiële verkoopdagen. Per persoon mag er
        maximaal 25 kg aan vuurwerk worden afgehaald.
        </p>

        <h2>Artikel 7: Betaling</h2>
        <p>
        Het aan Vuurwerk Bruchem verschuldigde bedrag dient voor het afhalen
        van de bestelling te worden voldaan.
        </p>
        <p>
        Betaling vindt plaats via de online betalingsmogelijkheden die tijdens
        het bestelproces worden aangeboden, tenzij de partijen anders
        overeenkomen.
        </p>
        <p>
        Het minimale orderbedrag is 25 Euro en er is het maximale orderbedrag
        is 10.000,00 Euro.
        </p>
        <p>
        Indien gekozen wordt voor de online betalingsmogelijkheid creditcard
        wordt het bedrag niet gereserveerd, maar wordt het direct afgeboekt
        (capturen) van de betreffende creditcard. Tenzij anders bepaald in de
        bijzondere Voorwaarden van de betrokken dienstverleners, worden
        betalingen op de website via Paypal, iDeal of creditcard gedaan via
        een beveiligd betalingssysteem.
        </p>

        <h2>Artikel 8: Wettelijke rente en buitengerechtelijke kosten</h2>
        <p>
        Bij niet tijdige betaling van een verschuldigd bedrag heeft de
        wederpartij de bevoegdheid de wettelijke rente ingevolge artikel 6:119
        van het Burgerlijke Wetboek, in rekening te brengen, te rekenen vanaf
        de dag van intreding van het verzuim.
        </p>
        <p>
        De door een partij in redelijkheid gemaakte buitengerechtelijke kosten
        komen voor rekening van de wederpartij.
        </p>

        <h2>Artikel 9: Annulering</h2>
        <p>
        Artikel 7: 46d lid 1-3 Burgerlijk Wetboek over zichttermijn is
        uitgesloten voor de aangeboden producten in deze webshop. De consument
        kan zijn bestelling kosteloos annuleren.
        </p>

        <h2>Artikel 10: Garantie en keuring</h2>
        <p>
        De dealer garandeert dat de door hem geleverde goederen voldoen aan de
        normen die hiertoe door de Nederlandse overheid zijn vastgesteld.
        </p>
        <p>
        Voorts garandeert de dealer dat de door hem geleverde goederen voldoen
        aan de eisen van bruikbaarheid, betrouwbaarheid en levensduur zoals
        deze door partijen bij het aangaan van de overeenkomst zijn bedoeld.
        </p>
        <p>
        De dealer garandeert de mededelingen die hij omtrent zijn product
        openbaar heeft gemaakt en/of (mede) ten behoeve van hem openbaar zijn
        gemaakt, alsmede de reclame-uitingen waarnaar hij verwezen heeft of
        waarvan hij anderszins de verwachting heeft gewekt dat de afnemer
        daarop kon vertrouwen.
        </p>

        <h2>Artikel 11: Gebreken; klachttermijnen</h2>
        <p>
        De consument is gehouden de gekochte goederen onmiddellijk na
        ontvangst te inspecteren of de dealer de overeenkomst deugdelijk is
        nagekomen en is voorts gehouden de dealer er terstond schriftelijk van
        in kennis te stellen zodra hem het tegendeel blijkt.
        </p>
        <p>
        De consument dient het onderzoek en de desbetreffende schriftelijke
        kennisgeving te doen uiterlijk vóór 5 januari van het jaar na
        aflevering.
        </p>
        <p>
        De nakoming van de overeenkomst geldt tussen partijen als deugdelijk
        indien de consument in gebreke is gebleven het onderzoek of de
        kennisgeving, bedoeld als hierboven, tijdig te doen.
        </p>
        <p>
        De prestatie van de dealer geldt in elk geval tussen partijen als
        deugdelijk, indien de consument het geleverde of een gedeelte van het
        geleverde in gebruik heeft genomen, heeft bewerkt of verwerkt, aan
        derden heeft geleverd respectievelijk in gebruik heeft doen nemen,
        heeft doen bewerken of verwerken of aan derden heeft doen leveren,
        tenzij de wederpartij het hierboven bepaalde in acht heeft genomen.
        </p>
        <p>
        Ook indien de consument tijdig reclameert, blijft zijn verplichting
        tot betaling en afname van gedane bestellingen bestaan. Goederen
        kunnen slechts na voorafgaande schriftelijke toestemming aan de dealer
        worden geretourneerd.
        </p>

        <h2>Artikel 12: Aansprakelijkheid</h2>
        <p>
        De dealer is niet aansprakelijk voor schade van welke aard ook die
        ontstaat doordat de goederen na aflevering verkeerd worden vervoerd of
        gebruikt. Onder verkeerd gebruik wordt in ieder geval, doch niet
        uitsluitend verstaan, de goederen gebruiken in voor het afsteken van
        vuurwerk slechte weersomstandigheden, de goederen binnen een gebouw
        en/of woning gebruiken of de goederen op een andere (strijdige) manier
        gebruiken dan op de verpakking is aangegeven.
        </p>
        <p>
        Tevens is de dealer niet aansprakelijk voor schade van welke aard ook
        die ontstaat nadat consument de goederen na aflevering in gebruik
        heeft genomen heeft bewerkt of verwerkt aan derden heeft geleverd,
        respectievelijk in gebruik heeft doen geven, heeft doen bewerken of
        verwerken of aan derden heeft doen leveren.
        </p>
        <p>
        De dealer is voorts niet aansprakelijk voor enige vorm van
        vervolgschade bijvoorbeeld in de vorm van omzetderving of verminderde
        goodwill in het bedrijf of het beroep van de consument.
        </p>
        <p>
        Indien de dealer ter zake van enige schade waarvoor hij krachtens de
        overeenkomst met de consument c.q. deze leveringsvoorwaarden niet
        aansprakelijk is, door een derde aansprakelijk wordt gesteld zal de
        consument de dealer ter zake volledig vrijwaren en de dealer alles
        vergoeden wat hij aan deze derde dient te voldoen.
        </p>
        <p>
        De aansprakelijkheid van de dealer uit hoofde van de overeenkomst met
        de consument is beperkt tot een zodanig bedrag als naar maatstaven van
        redelijkheid en billijkheid in verhouding staat tot de overeengekomen
        prijs, doch in ieder geval tot een maximum van drie maal het
        orderbedrag en mits deze aansprakelijkheid door de verzekering van de
        dealer wordt gedekt.
        </p>

        <h2>Artikel 13: Toepasselijk recht bij geschillen</h2>
        <p>
        Alle geschillen ter zake van de totstandkoming, de uitleg of de
        uitvoering van een overeenkomst met de dealer worden bij uitsluiting
        voorgelegd aan de bevoegde rechter te Zutphen. Indien dit een andere
        dan de wettelijk bevoegde rechter is, geeft de dealer de consument een
        termijn van een maand nadat de dealer zich schriftelijk op dit beding
        heeft beroepen, om schriftelijk voor beslechting van het geschil door
        de wettelijk bevoegde rechter te kiezen.
        </p>
        <p>
        Op de overeenkomst, de uitleg en de uitvoering daarvan is Nederlands
        recht van toepassing.
        </p>
        <p>
        Vorenstaande laat onverlet het recht om een geschil voor te leggen aan
        de bevoegde rechter.
        </p>

        <h2>Artikel 14: Partiële nietigheid</h2>
        <p>
        Indien één of meerdere bepalingen uit de overeenkomst of de algemene
        voorwaarden nietig dan wel ongeldig worden verklaard blijven de
        overige bepalingen van de overeenkomst en de algemene voorwaarden
        onverminderd tussen partijen van kracht.
        </p>
        <p>
        Partijen verplichten zich er toe de nietige dan wel ongeldige
        bepaling(en) te vervangen door één of meerdere nieuwe bindende
        bepaling(en) op zodanige wijze dat de nieuwe bepaling(en) zo weinig
        mogelijk afwijk(t)(en) van de bepaling(en) die zij vervang(t)(en)
        daarbij zoveel mogelijk rekening houdend met de oorspronkelijke
        bedoeling van partijen.
        </p>

        <h2>Artikel 15: Diversen</h2>
        <p>
        Gratis vuurwerk wordt door Vuurwerk Bruchem zelf bepaald en geldt
        alleen in de voorverkoop.
        </p>
        <p>
        Wij hebben voldoende voorraad, maar mocht er toch een door u besteld
        artikel zijn uitverkocht, dan leveren wij daarvoor een gelijkwaardig
        of duurder artikel.
        </p>
        <p>
        Artikel- en prijswijzigingen als gevolg van wettelijke regelingen of
        bepaling zijn voorbehouden.
        </p>
        <p>De artikelen zijn niet op ware grootte afgebeeld.</p>
        <p>
        Bij verkoop en gebruik van consumentenvuurwerk gelden de volgende
        minimum leeftijden:
        <ul>
            <li>Categorie 1: 12 jaar</li>
            <li>Categorie 2: 16 jaar</li>
        </ul>
        </p>
        <p>
        Als er geen categorie vermeld staat op het vuurwerk dan is de op de
        verpakking vermeldde minimum leeftijd bindend, maar minstens 16 jaar.
        </p>

        <h2>Artikel 16: Wijziging van de voorwaarden</h2>
        <p>
        Vuurwerk Bruchem is bevoegd wijzigingen in deze overeenkomst aan te
        brengen. Deze wijzigingen treden in werking op het aangekondigde
        tijdstip van inwerkingtreden.
        </p>
        <p>
        Vuurwerk Bruchem zal de gewijzigde voorwaarden tijdig aan de
        wederpartij toezenden. Indien geen tijdstip van inwerkingtreden is
        medegedeeld treden wijzigingen jegens de opdrachtgever in werking
        zodra hem de wijziging is medegedeeld.
        </p>

        <h2>Artikel 17: Privacy statement</h2>
        <p>
        Vuurwerk Bruchem en Dingemans Machines zullen geen gebruik maken van
        de persoonlijke gegevens die via het bestelproces worden
        geregistreerd, tenzij de consument daartoe op enige wijze toestemming
        heeft gegeven.
        </p>
        <p>
        Persoonlijke gegevens worden dan ook zorgvuldig verwerkt en beveiligd.
        Hierbij houden wij ons aan de eisen die de Wet bescherming
        persoonsgegevens stelt.
        </p>
</div></div>

  );
};

export default VerkoopVoorwaarden;
