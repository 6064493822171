import React, { useState } from "react";
import { Box, InputBase, Divider, Typography, IconButton } from "@mui/material";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import CircularProgress from '@mui/material/CircularProgress';

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState("");
  const [isLoading, setIsLoading] = useState(false); 
  const subscribeToNewsletter = async () => {
    setIsLoading(true);  
    try {
      const response = await fetch('https://api.vuurwerkbruchem.com/strapi-newsletter/newsletter/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
        })
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setSubscriptionSuccess(true);
        setSubscriptionError(""); 
        setEmail("");  
      } else {
        setSubscriptionError(data.message || "Er is een fout opgetreden bij het inschrijven voor de nieuwsbrief.");
      }
    } catch (error) {
      console.error("Fout bij inschrijven:", error);
      setSubscriptionError("Er is een fout opgetreden bij het inschrijven voor de nieuwsbrief.");
    } finally {
      setIsLoading(false);  
    }
  };
  
  

  return (
    <Box width="80%" margin="80px auto" textAlign="center">
      <IconButton>
        <MarkEmailReadOutlinedIcon fontSize="large" />
      </IconButton>
      <Typography variant="h3">Abonneer op onze nieuwsbrief</Typography>
      <Typography>
        En ontvang de beste vuurwerkdeals direct in jouw inbox!
      </Typography>
      <Box
        p="2px 4px"
        m="15px auto"
        display="flex"
        alignItems="center"
        width="75%"
        backgroundColor="#F2F2F2"
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Vul je e-mailadres in"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <Typography
          sx={{ 
            p: "10px", 
            ":hover": { cursor: isLoading ? "default" : "pointer" }
          }}
          onClick={() => {
            if (!isLoading) {  
              subscribeToNewsletter();
            }
          }}
        >
          {isLoading ? "ogenblikje..." : "abonneer!"}
        </Typography>

      </Box>
      {subscriptionSuccess && (
        <Typography sx={{ color: "green" }}>
          Je inschrijving is gelukt! Bedankt voor je inschrijving.
        </Typography>
      )}
      {subscriptionError && (
        <Typography sx={{ color: "red" }}>{subscriptionError}</Typography>
      )}
    </Box>
  );
};

export default Subscribe;
