import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";  
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BASE_URL } from "../../api";

const API_BASE_URL = BASE_URL;

export default function MainCarousel() {

  const [carouselData, setCarouselData] = useState([]);

  const [items, setItems] = useState([]);
  
  const [chosenItems, setChosenItems] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/items?populate=image&filters[highlight][$eq]=true`)
      .then(response => {
        if (!response.ok) {
          throw new Error("Error fetching items");
        }
        return response.json();
      })
      .then(data => {
        if (data && data.data) {
          setItems(data.data);
          

        }
      })
      .catch(err => console.error(err));

    fetch(`${API_BASE_URL}/api/carousels?populate=image`)
      .then(response => {
        if (!response.ok) {
          throw new Error("Error fetching carousels");
        }
        return response.json();
      })
      .then(data => {
        if (data && data.data) {
          setCarouselData(data.data);
        } else {
          console.error("Invalid data from server:", data);  
        }
      })
      .catch(err => console.error(err));

  }, []);
  useEffect(() => {
    if (items.length > 0) {
      let uniqueItems = new Set();
      while (uniqueItems.size < 3) {
        uniqueItems.add(items[Math.floor(Math.random() * items.length)]);
      }
      setChosenItems([...uniqueItems]);
    }
  }, [items]);

  return (
    <Box
      display="flex"
      justifyContent="center" 
      width="100%"
      marginTop="64px"
      className="shift-on-search"
      sx={{
        "@media (max-width: 768px)": {
          display: "none"
        }
      }}
    >
      <Box width="80%" height="40vh" display="flex">
      
        {/* Carousel */}
        <Box width="78%" height="100%">
          <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            renderArrowPrev={(onClickHandler, hasPrev, label) => (
              <IconButton
                onClick={onClickHandler}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "0",
                  color: "white",
                  padding: "5px",
                  zIndex: "10"
                }}
              >
                <NavigateBeforeIcon sx={{ fontSize: 40 }} />
              </IconButton>
            )}
            renderArrowNext={(onClickHandler, hasNext, label) => (
              <IconButton
                onClick={onClickHandler} 
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "0",
                  color: "white",
                  padding: "5px",
                  zIndex: "10"
                }}
              >
                <NavigateNextIcon sx={{ fontSize: 40 }} />
              </IconButton>
            )}
          >
          
            {carouselData.map((item) => (
              <Box
                key={item.id}
                style={{
                  backgroundImage: `url(${API_BASE_URL}${item.attributes.image.data.attributes.url})`,
                  backgroundSize: "contain",
                  height: "70vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  color: "white",
                }}
                onClick={() =>
                  (window.location.href = item.attributes.callToAction)
                }
              >
                <Box
                  bgcolor="rgba(0, 0, 0, 0.6)"
                  p={2}
                  borderRadius={1}
                  sx={{
                    height: "100%",
                    width: "45%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Roboto, sans-serif",
                      color: "white",
                      marginTop: "1.5em", // Voeg deze regel toe
                      marginBottom: "1rem",
                    }}
                    variant="h2"
                    gutterBottom
                  >
                    {item.attributes.bigText}
                  </Typography>

                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Roboto, sans-serif",
                      color: "white",
                      flexGrow: 1,
                    }}
                    variant="h4"
                    gutterBottom
                  >
                    {item.attributes.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      
        {/* Chosen items */}
        <Box
          width="20%"
          height="42vh"
          display="flex"
          flexDirection="column"
          flexWrap="wrap"  
        >
        
          {chosenItems.map(item => (
            <a
              href={`/item/${item.id}`}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
                flexBasis: "33.33%",
                height: "33.33%",
                display: "flex",
                alignItems: "stretch"
              }}
            >
              <Box
                key={item.id}
                width="100%"
                height="100%"
                style={{
                  backgroundColor: `rgb(${item.attributes.bgcolor})`,
                  position: "relative"
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                border="1vh solid white"
                bordertop="0px solid" 
                padding="0px"
                marginTop="-1vh"
                marginBottom=""
                boxSizing="border-box"
              >
                <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    width: "100%",
                    height: "100%"
                  }}
                >
                  <img 
                    src={`${API_BASE_URL}${item.attributes.image.data.attributes.url}`}
                    alt={item.attributes.name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      display: "block",
                      margin: "auto"
                    }}
                  />
                  <h3
                    style={{
                      margin: "10px 0",
                      position: "absolute",
                      top: -10,
                      left: -5, 
                      color: "white",
                      zIndex: "1",
                      padding: "10px",
                      textAlign: "left"
                    }}
                  >
                    {item.attributes.name}
                  </h3>
                  <p
                    style={{
                      margin: "0",
                      position: "absolute",
                      bottom: 0,
                      left: 0, 
                      color: "white",
                      zIndex: "1",
                      padding: "10px",
                      fontFamily: "'Rubik', sans-serif",
                      fontWeight: "bold"
                    }}
                  >
                    €{item.attributes.priceDiscount}
                  </p>
                </div>
              </Box>
            </a>
          ))}
        
        </Box>


        {/* Side text */}
        <Box
          width="2%"
          height="100%"
          bgcolor="rgb(227, 25, 126)"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{
              transform: "rotate(-90deg)",
              fontWeight: "bold",
              color: "white",
              fontSize: "1.23rem",
              textAlign: "center",
              whiteSpace: "nowrap"
            }}
          >
            ONZE FAVORIETE ARTIKELEN!
          </Typography>
        </Box>

      </Box>
    </Box>
  );
}