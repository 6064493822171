import React, { useState, useEffect } from "react";
import CookieWall from './components/CookieWall';
import { AuthProvider } from './AuthContext';
import AuthModal from "./components/AuthModal";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./scenes/home/Home";
import Navbar from "./scenes/global/Navbar";
import Footer from "./scenes/global/Footer";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import CartMenu from "./scenes/global/CartMenu";
import Checkout from "./scenes/checkout/Checkout";
import Confirmation from "./scenes/checkout/Confirmation";
import Products from "./scenes/products/Products";
import Favorites from "./scenes/favorites/Favorites";
import SearchResults from "./scenes/searchResults/SearchResults";
import PrivacyPolicy from "./scenes/PrivacyPolicy";
import VerkoopVoorwaarden from "./scenes/VerkoopVoorwaarden";
import OrdersPage from "./components/OrdersPage";
import ProfilePage from "./components/ProfilePage";
import LoadingAnimation from "./components/LoadingAnimation";
import 'swiper/css';
import Swiper from 'swiper';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isRegisterModalOpen, setRegisterModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const password = prompt("Voer het wachtwoord in:");
    if (password === "vwb123!") {
      setIsAuthorized(true);
    }
  }, []);

  const openAuthModal = () => setIsAuthModalOpen(true);
  const closeAuthModal = () => setIsAuthModalOpen(false);

  if (!isAuthorized) {
    return <div>Deze site is in ontwikkeling.</div>;
  }

  return (
    <div className="app">
      <AuthProvider>
        <BrowserRouter>
          <AuthModal isOpen={isAuthModalOpen} onClose={closeAuthModal} />
          <Navbar onAuthClick={openAuthModal} />
          <ScrollToTop />
          <Routes>
            <Route path="/verkoopvoorwaarden" element={<VerkoopVoorwaarden />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Home />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="checkout/success/:sessionId?" element={<Confirmation />} />
            <Route path="/shoppinglist" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/orders" element={<OrdersPage />} />
          </Routes>
          <CartMenu />
          <Footer />
        </BrowserRouter>
      </AuthProvider>
      <CookieWall />
    </div>
  );
}

export default App;
