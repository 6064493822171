import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Item from "../../components/Item";
import { BASE_URL } from "../../api";
const API_BASE_URL = BASE_URL;

const Favorites = () => {
  const [favoriteItems, setFavoriteItems] = useState([]);

  useEffect(() => {
    fetchFavoriteItems();
  }, []);

  const fetchFavoriteItems = async () => {
    const storedFavorites = JSON.parse(
      localStorage.getItem("favorites") || "[]"
    );

    try {
      const responses = await Promise.all(
        storedFavorites.map(
          (id) => fetch(`${API_BASE_URL}/api/items/${id}?populate=image`) // Include "populate=image" to fetch image data
        )
      );
      const data = await Promise.all(
        responses.map((response) => response.json())
      );
      setFavoriteItems(data.map((d) => d.data));
    } catch (error) {
      console.error("Error fetching favorite items:", error);
    }
  };

  const removeFromFavorites = (id) => {
    const updatedFavorites = favoriteItems.filter((item) => item.id !== id);
    localStorage.setItem(
      "favorites",
      JSON.stringify(updatedFavorites.map((item) => item.id))
    );
    setFavoriteItems(updatedFavorites);
  };

  return (
    <Box width="80%" m="80px auto" className="shift-on-search">
      <Typography variant="h3" mb={4}>
        Mijn favorieten
      </Typography>

      <Box display="flex" flexDirection="column" gap="20px">
        {favoriteItems.map((item) => (
          <Box
            key={item.id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Item item={item} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => removeFromFavorites(item.id)}
              sx={{
                backgroundColor: "#222222",
                color: "white",
                borderRadius: 0,
                minWidth: "150px",
                padding: "10px 40px",
              }}
            >
              Verwijderen van favorieten
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Favorites;
