import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

const Shipping = ({ values, errors, touched, handleChange, handleBlur }) => {
  const isMobile = useMediaQuery("(max-width:599px)");

  return (
    <Box sx={{ marginBottom: "4vh" }}>
      <Typography variant="h6">Je gegevens</Typography>

      <Box
        display="grid"
        gridTemplateColumns={isMobile ? "1fr" : "repeat(4, 1fr)"}
        gap="15px"
      >
        <TextField
          fullWidth
          sx={{ gridColumn: isMobile ? "span 1" : "span 2", marginTop: "4vh" }}
          name="firstName"
          label="Voornaam"
          type="text"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstName}
          error={!!touched.firstName && !!errors.firstName}
          helperText={touched.firstName && errors.firstName}
        />
        <TextField
          fullWidth
          type="text"
          name="lastName"
          label="Achternaam"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastName}
          sx={{
            gridColumn: isMobile ? "span 1" : "span 2",
            marginTop: isMobile ? "0" : "4vh",
          }}
          error={!!touched.lastName && !!errors.lastName}
          helperText={touched.lastName && errors.lastName}
        />
        <TextField
          fullWidth
          type="text"
          label="Land"
          name="country"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.country}
          sx={{ gridColumn: isMobile ? "span 1" : "span 4" }}
          error={!!touched.country && !!errors.country}
          helperText={touched.country && errors.country}
        />
        <TextField
          fullWidth
          type="text"
          label="Straat"
          onBlur={handleBlur}
          name="street"
          onChange={handleChange}
          value={values.street}
          sx={{ gridColumn: isMobile ? "span 1" : "span 3" }}
          error={!!touched.street && !!errors.street}
          helperText={touched.street && errors.street}
        />
        <TextField
          fullWidth
          type="text"
          label="Huisnummer"
          onBlur={handleBlur}
          name="houseNumber"
          onChange={handleChange}
          value={values.houseNumber}
          sx={{ gridColumn: isMobile ? "span 1" : "span 1" }}
          error={!!touched.houseNumber && !!errors.houseNumber}
          helperText={touched.houseNumber && errors.houseNumber}
        />
        <TextField
          fullWidth
          type="text"
          name="postalCode"
          label="Postcode"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.postalCode}
          sx={{ gridColumn: isMobile ? "span 1" : "1fr" }}
          error={!!touched.postalCode && !!errors.postalCode}
          helperText={touched.postalCode && errors.postalCode}
        />
        <TextField
          fullWidth
          type="text"
          label="Woonplaats"
          name="city"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.city}
          sx={{ gridColumn: isMobile ? "span 1" : "span 3" }}
          error={!!touched.city && !!errors.city}
          helperText={touched.city && errors.city}
        />
      </Box>
    </Box>
  );
};

export default Shipping;
