import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Tooltip,

} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { shades } from "../../theme";
import {
  decreaseCount,
  increaseCount,
  removeFromCart,
  setIsCartOpen,
  setCount, // Zorg ervoor dat deze actie beschikbaar is in je Redux store
} from "../../state";
import { BASE_URL } from "../../api";

const API_BASE_URL = BASE_URL;

const useFreeItem = (totalPrice) => {
  const [freeItem, setFreeItem] = React.useState(null);
  const [alwaysFreeItems, setAlwaysFreeItems] = React.useState([]);

  // Fetch always free items (freeFromPrice = 0)
  React.useEffect(() => {
    fetch(
      `${API_BASE_URL}/api/free-items?populate=image&filters[freeFromPrice][$eq]=0`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && Array.isArray(data.data)) {
          setAlwaysFreeItems(data.data);
        }
      });
  }, []);

  React.useEffect(() => {
    fetch(
      `${API_BASE_URL}/api/free-items?populate=image&filters[freeFromPrice][$lte]=${totalPrice}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && Array.isArray(data.data)) {
          const eligibleItems = data.data.filter(
            (item) =>
              item.attributes &&
              item.attributes.freeFromPrice <= totalPrice &&
              item.attributes.freeFromPrice !== 0
          );
          if (eligibleItems.length) {
            eligibleItems.sort(
              (a, b) => b.attributes.freeFromPrice - a.attributes.freeFromPrice
            );
            setFreeItem(eligibleItems[0]);
          } else {
            // Als er geen in aanmerking komende items zijn, zet freeItem op null
            setFreeItem(null);
          }
        }
      });
  }, [totalPrice]);

  return [freeItem, alwaysFreeItems];
};

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GrayedIconButton = styled(IconButton)`
  color: #aaa !important;
  cursor: not-allowed !important;
`;
const StrikethroughPrice = styled(Typography)`
  text-decoration: line-through;
  color: ${shades.neutral[500]};
  margin-right: 10px;
`;
const CartMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);

  // Functie om de huidige voorraad op te halen
  const fetchStockQuantity = async (itemId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/items/${itemId}`);
      const data = await response.json();
      return data.data.attributes.stockQuantity;
    } catch (error) {
      console.error("Fout bij het ophalen van de voorraad:", error);
      return -1; // Geeft aan dat de voorraad niet kon worden opgehaald
    }
  };

// Voorraadcontrole en aanpassing van itemaantallen in de winkelwagen
const handleCountChange = async (item, increment = true) => {
  const currentStock = await fetchStockQuantity(item.id);
  // Foutcontrole voor het geval de voorraad niet opgehaald kan worden.
  if (currentStock < 0) {
    // Hier kun je een foutmelding tonen
    return;
  }

  let newCount = increment ? item.count + 1 : item.count - 1;

  // Als de voorraad null (onbekend) is, beperk de aantallen niet.
  if (currentStock !== null) {
    // Controleer of er een maximum is ingesteld voor deze item in de winkelwagen
    if (item.attributes.maxPerOrder !== null) {
      // Als het maximumaantal kleiner is dan de voorraad, stel het nieuwe aantal in op het maximum
      if (newCount > item.attributes.maxPerOrder) {
        newCount = item.attributes.maxPerOrder;
        alert(`Maximale hoeveelheid ${item.attributes.name} per bestelling: ${item.attributes.maxPerOrder}. We hebben het daarom weer verlaagd naar ${item.attributes.maxPerOrder}. `);
      }
    }

    // Beperk het nieuwe aantal op basis van de beschikbare voorraad
    if (newCount > currentStock) {
      newCount = currentStock;
      alert(`Het gewenste aantal van ${item.attributes.name} overschrijdt de beschikbare voorraad. We hebben het aantal aangepast naar ${currentStock}.`);
    }
  }

  dispatch(setCount({ id: item.id, count: newCount }));
};


  const totalPrice = parseFloat(
    cart
      .reduce((total, item) => {
        const productPrice = item.attributes.priceDiscount
          ? item.attributes.priceDiscount
          : item.attributes.price;
        return total + item.count * productPrice;
      }, 0)
      .toFixed(2)
  );
  const [freeItem, alwaysFreeItems] = useFreeItem(totalPrice);

  const isCartOpen = useSelector((state) => state.cart.isCartOpen);

  return (
    <Box
      display={isCartOpen ? "block" : "none"}
      backgroundColor="rgba(0, 0, 0, 0.4)"
      position="fixed"
      zIndex={10}
      width="100%"
      height="100%"
      left="0"
      top="0"
      overflow="auto"
    >
      <Box
        position="fixed"
        right="0"
        bottom="0"
        width="max(400px, 30%)"
        height="100%"
        backgroundColor="white"
      >
        <Box padding="30px" overflow="auto" height="100%">
          <FlexBox mb="15px">
            <Typography variant="h3">WINKELWAGEN ({cart.length})</Typography>
            <IconButton onClick={() => dispatch(setIsCartOpen({}))}>
              <CloseIcon />
            </IconButton>
          </FlexBox>

          <Divider />

          <Box>
            {cart.map((item) => {
              const productPrice = parseFloat(
                (
                  item.count *
                  (item.attributes.priceDiscount
                    ? item.attributes.priceDiscount
                    : item.attributes.price)
                ).toFixed(2)
              );
              return (
                <Box key={`${item.attributes.name}-${item.id}`}>
                  <FlexBox p="15px 0">
                    <Box flex="1 1 40%">
                      '
                      
                      <img
                        onClick={() => {
                          navigate(`/item/${item.id}`);
                          dispatch(setIsCartOpen({}));
                        }}
                        alt={item?.attributes?.name}
                        width="50%"
                        height="auto"
                        src={`${API_BASE_URL}${
                          item?.attributes?.image?.data?.attributes?.formats
                            ?.medium?.url ||
                          item?.attributes?.image?.data?.attributes?.url
                        }`}
                        style={{
                          objectFit: "contain",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                    <Box flex="1 1 60%">
                      <FlexBox mb="5px">
                        <Typography fontWeight="bold">
                          {item.attributes.name}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            dispatch(removeFromCart({ id: item.id }))
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </FlexBox>
                      <Typography>
                        {item.attributes.shortDescription}
                      </Typography>
                      <FlexBox m="15px 0">
                        <Box
                          display="flex"
                          alignItems="center"
                          border={`1.5px solid ${shades.neutral[500]}`}
                        >
                          <IconButton
                            onClick={() => handleCountChange(item, false)}
                            disabled={item.count <= 1}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <Typography>{item.count}</Typography>
                          <IconButton
                            onClick={() => handleCountChange(item, true)}
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                        <Typography fontWeight="bold">
                          €{productPrice.toFixed(2)}
                        </Typography>
                      </FlexBox>
                    </Box>
                  </FlexBox>
                  <Divider />
                </Box>
              );
            })}
          </Box>

          {alwaysFreeItems.length > 0 && (
            <Box mt={3}>
              <Typography variant="h6">Gratis Producten</Typography>
              {alwaysFreeItems.map((item) => (
                <Box key={`always-free-${item.id}`}>
                  <FlexBox p="15px 0">
                    <Box flex="1 1 40%">
                      <img
                        alt={item?.attributes?.name}
                        width="50%"
                        height="auto"
                        src={`${API_BASE_URL}${
                          item?.attributes?.image?.data?.attributes?.formats
                            ?.medium?.url ||
                          item?.attributes?.image?.data?.attributes?.url
                        }`}
                        style={{
                          objectFit: "contain",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </Box>
                    <Box flex="1 1 60%">
                      <FlexBox mb="5px">
                        <Typography fontWeight="bold">
                          {item.attributes.name}
                        </Typography>
                        <Tooltip title="Cannot remove a free item">
                          <GrayedIconButton disabled>
                            <CloseIcon />
                          </GrayedIconButton>
                        </Tooltip>
                      </FlexBox>
                      <Typography>
                        {item.attributes.shortDescription}
                      </Typography>
                      <FlexBox m="15px 0">
                        <Box
                          display="flex"
                          alignItems="center"
                          border={`1.5px solid ${shades.neutral[500]}`}
                        >
                          <GrayedIconButton disabled>
                            <RemoveIcon />
                          </GrayedIconButton>
                          <Typography>{item.count || 1}</Typography>
                          <GrayedIconButton disabled>
                            <AddIcon />
                          </GrayedIconButton>
                        </Box>
                        <StrikethroughPrice>
                          €{parseFloat(item.attributes.price).toFixed(2)}
                        </StrikethroughPrice>
                      </FlexBox>
                    </Box>
                  </FlexBox>
                  <Divider />
                </Box>
              ))}
            </Box>
          )}

          {freeItem && (
            <Box mt={3}>
              <FlexBox p="15px 0">
                <Box flex="1 1 40%">
                  <img
                    alt={freeItem.attributes.name}
                    width="50%"
                    height="auto"
                    src={`${API_BASE_URL}${
                      freeItem?.attributes?.image?.data?.attributes?.formats
                        ?.medium?.url ||
                      freeItem?.attributes?.image?.data?.attributes?.url
                    }`}
                    style={{
                      objectFit: "contain",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </Box>
                <Box flex="1 1 60%">
                  <FlexBox mb="5px">
                    <Typography fontWeight="bold">
                      {freeItem.attributes.name}
                    </Typography>
                    <Tooltip title="Cannot remove a free item">
                      <GrayedIconButton disabled>
                        <CloseIcon />
                      </GrayedIconButton>
                    </Tooltip>
                  </FlexBox>
                  <Typography>
                    {freeItem.attributes.shortDescription}
                  </Typography>
                  <FlexBox m="15px 0">
                    <Box
                      display="flex"
                      alignItems="center"
                      border={`1.5px solid ${shades.neutral[500]}`}
                    >
                      <GrayedIconButton disabled>
                        <RemoveIcon />
                      </GrayedIconButton>
                      <Typography>{freeItem.count || 1}</Typography>
                      <GrayedIconButton disabled>
                        <AddIcon />
                      </GrayedIconButton>
                    </Box>
                    <StrikethroughPrice>
                      €{parseFloat(freeItem.attributes.price).toFixed(2)}
                    </StrikethroughPrice>
                  </FlexBox>
                </Box>
              </FlexBox>
              <Divider />
            </Box>
          )}

          {/* ACTIONS */}
          <Box m="20px 0">
            <FlexBox m="20px 0">
              <Typography fontWeight="bold">SUBTOTAL</Typography>
              <Typography fontWeight="bold">
                €{totalPrice.toFixed(2)}
              </Typography>
            </FlexBox>
            {totalPrice < 1 && (
              <Typography color="error" textAlign="center" mb={2}>
                Online bestellen kan vanaf €1.
              </Typography>
            )}
           <Button
              sx={{
                backgroundColor:
                  totalPrice < 1 ? shades.neutral[500] : shades.primary[400],
                color: "white",
                borderRadius: 0,
                minWidth: "100%",
                padding: "20px 40px",
                m: "20px 0",
              }}
              disabled={totalPrice < 1}
              onClick={() => {
                navigate("/checkout");
                dispatch(setIsCartOpen({}));
              }}
            >
              AFREKENEN
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartMenu;
