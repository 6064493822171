import React, { useEffect, useState } from "react";
import MainCarousel from "./MainCarousel";
import ShoppingList from "./ShoppingList";
import Subscribe from "./Subscribe";

const Home = () => {
  const [isFadingIn, setIsFadingIn] = useState(true);

  useEffect(() => {
    setIsFadingIn(false);
  }, []);

  return (
    <div
      className={`home-wrapper ${isFadingIn ? "fade-in" : ""}`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <MainCarousel />
      <ShoppingList />
      <Subscribe />
    </div>
  );
};

export default Home;
